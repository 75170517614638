import {Link, Typography} from "@mui/material";
import {GlobalSuccessBox} from "../components/GlobalSuccessBox.tsx";

export const PageVielenDank = () => {

	return <GlobalSuccessBox title={'Vielen Dank'}>
		<Typography variant={'body1'} mb={3}>Danke, dass Sie sich für das Alternativangebot entschieden haben. Ihr neuer Versicherungsschein ist auf dem Weg zu Ihnen.</Typography>
		<Typography variant={'body1'} mb={3}>Kennen Sie schon unser Online Kundenportal? Nein, dann klicken Sie <Link
			target={'_blank'}
			href={'https://www.barmenia.de/deu/bde_privat/bde_service/bde_selfservice/bde_meine_barmenia/uebersicht.xhtml'}>hier</Link> und
			vereinfachen Sie zukünftig Ihre Vertragsverwaltung. </Typography>
		<Typography variant={'body1'} mb={3}>Ihre Barmenia-Kundenbetreuung</Typography>
		<Typography variant={'body1'}>Barmenia Allgemeine Versicherungs-AG</Typography>
		<Typography variant={'body1'}>Barmenia-Allee 1</Typography>
		<Typography variant={'body1'}>42119 Wuppertal</Typography>
		<Typography variant={'body1'}>0202 438 3643</Typography>
		<Typography variant={'body1'}><Link href={'mailto:tierversicherung@barmenia.de'}>tierversicherung@barmenia.de</Link></Typography>
	</GlobalSuccessBox>
}
